<template>
	<modal class="NoxModalZP" name="NoxModalZP" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addZP'">Добавление NOX ZP</span>
					<span v-else-if="noxType == 'editZP'">Редактирование NOX ZP</span>
					<span v-else-if="noxType == 'deleteZP'">Удаление NOX ZP</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['addZP', 'editZP'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Тип начисления:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxZPType" placeholder="тип начисления" :options="noxZPTypes"></v-select>
								<div v-html="noxAlertZPType"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">NOX ID пользователя:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxZPUserId" placeholder="nox id пользователя" autocomplete="off">
								<div v-html="noxAlertZPUserId"></div>
							</div>
						</div>
						<div class="nox_modal_info_row" v-if="!noxZPType || noxZPType.code == 0">
							<div class="nox_modal_info_row_label">Сумма начисления:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxZPAmount" placeholder="сумма начисления" autocomplete="off">
								<div v-html="noxAlertZPAmount"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">День начисления:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxZPPayday" placeholder="день начисления" :options="noxZPPaydays"></v-select>
								<div v-html="noxAlertZPPayday"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Примечание:</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxZPComment" placeholder="примечание"></textarea>
								<div v-html="noxAlertZPComment"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Статус:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxZPStatus)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStatus"></toggle-button>
								<div v-html="noxAlertZPStatus"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteZP'">Вы действительно хотите удалить NOX ZP?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'addZP'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editZP'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteZP'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertZPType: '',
			noxAlertZPUserId: '',
			noxAlertZPAmount: '',
			noxAlertZPPayday: '',
			noxAlertZPStatus: '',
			noxAlertZPComment: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxZPType: '',
			noxZPTypes: [],
			noxZPUserId: '',
			noxZPAmount: '',
			noxZPPayday: '',
			noxZPPaydays: [],
			noxZPStatus: 0,
			noxZPComment: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxZPType = '';
				this.noxZPTypes = [
					{ code: 0, label: 'NOX ZP' },
					{ code: 1, label: 'NOX Box ZP' }
				];
				this.noxZPUserId = '';
				this.noxZPAmount = '';
				this.noxZPPayday = '';
				this.noxZPPaydays = [
					{ code: 0, label: 'Каждый день' }
				];
				this.noxZPStatus = 0;
				this.noxZPComment = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				for (var i = 1; i <= 28; i++) {
					this.noxZPPaydays.push({ code: Number(i), label: i });
				}

				if (this.noxType == 'editZP') {

					this.noxZPType = this.noxZPTypes[this.$parent.noxZPData[this.noxIndex].type];
					this.noxZPUserId = this.$parent.noxZPData[this.noxIndex].user_id;
					this.noxZPAmount = this.$parent.noxZPData[this.noxIndex].amount;
					this.noxZPPayday = this.noxZPPaydays[this.$parent.noxZPData[this.noxIndex].payday];
					this.noxZPStatus = this.$parent.noxZPData[this.noxIndex].status;
					this.noxZPComment = this.$parent.noxZPData[this.noxIndex].comment;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertZPType = '';
				this.noxAlertZPUserId = '';
				this.noxAlertZPAmount = '';
				this.noxAlertZPPayday = '';
				this.noxAlertZPStatus = '';
				this.noxAlertZPComment = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id NOX ZP.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id NOX ZP.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать тип начисления.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный тип начисления.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать NOX ID пользователя.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный NOX ID пользователя.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать сумму начисления.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верную сумму начисления.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо выбрать день начисления.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо выбрать верный день начисления.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать примечание.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верное примечание. Разрешено вводить буквы, цифры и некоторые знаки препинания. Длина: до 200 символов.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать статус.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный статус.'; }
				else if (i == 15) { this.noxTemp = 'Id NOX ZP не найден в системе.'; }
				else if (i == 16) { this.noxTemp = 'NOX ID пользователя не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addZP', 'editZP'].includes(_this.noxType)) {
						if (!_this.noxZPType) {
							_this.noxAlertZPType = _this.getError(3);
						}
						if (!_this.noxZPUserId) {
							_this.noxAlertZPUserId = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxZPUserId) || _this.noxZPUserId < 1) {
							_this.noxAlertZPUserId = _this.getError(6);
						}
						if (_this.noxZPType && _this.noxZPType.code == 0) {
							if (!_this.noxZPAmount) {
								_this.noxAlertZPAmount = _this.getError(7);
							}
							else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxZPAmount)) {
								_this.noxAlertZPAmount = _this.getError(8);
							}
						}
						if (!_this.noxZPPayday) {
							_this.noxAlertZPPayday = _this.getError(9);
						}
						if (!_this.noxZPComment) {
							_this.noxAlertZPComment = _this.getError(11);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxZPComment) || _this.noxZPComment.length > 200) {
							_this.noxAlertZPComment = _this.getError(12);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/zp';
							config.data = { id: _this.noxId, type: _this.noxZPType.code, user_id: _this.noxZPUserId, amount: Number(_this.noxZPAmount), payday: _this.noxZPPayday.code, comment: _this.noxZPComment, status: _this.noxZPStatus };
							config.method = _this.noxType == 'addZP' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteZP') {
						config.url = '/v2/admin/account/zp';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addZP') {
									_this.$parent.noxZPData.push(data.data);
								}
								else if (_this.noxType == 'editZP') {
									_this.$parent.noxZPData.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteZP') {
									_this.$parent.noxZPData.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addZP', 'editZP', 'deleteZP'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertZPType = _this.getError(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertZPType = _this.getError(4); }
										else if (data.response.data.error == 'USER_ID_IS_EMPTY') { _this.noxAlertZPUserId = _this.getError(5); }
										else if (data.response.data.error == 'USER_ID_NOT_VALID') { _this.noxAlertZPUserId = _this.getError(6); }
										else if (data.response.data.error == 'AMOUNT_IS_EMPTY') { _this.noxAlertZPAmount = _this.getError(7); }
										else if (data.response.data.error == 'AMOUNT_NOT_VALID') { _this.noxAlertZPAmount = _this.getError(8); }
										else if (data.response.data.error == 'PAYDAY_IS_EMPTY') { _this.noxAlertZPPayday = _this.getError(9); }
										else if (data.response.data.error == 'PAYDAY_NOT_VALID') { _this.noxAlertZPPayday = _this.getError(10); }
										else if (data.response.data.error == 'COMMENT_IS_EMPTY') { _this.noxAlertZPComment = _this.getError(11); }
										else if (data.response.data.error == 'COMMENT_NOT_VALID') { _this.noxAlertZPComment = _this.getError(12); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertZPStatus = _this.getError(13); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertZPStatus = _this.getError(14); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addZP', 'editZP', 'deleteZP'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(15); }
										else if (data.response.data.error == 'USER_ID_NOT_FOUND') { _this.noxAlertZPUserId = _this.getError(16); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStatus: function(event) {
				this.noxZPStatus = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalZP');
			}
		}
	}
</script>
